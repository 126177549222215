import React from "react";

const SelectRangeOptions = ({ onChange, value }) => {
  return (
    <select
      className="filters__input  filters__input--range"
      onChange={onChange}
      name="view"
      value={!value ? "" : value}
    >
      <option>None Selected</option>
      <option value="appts">Appts</option>
      <option value="crew_jobs">Crew Jobs</option>
      <option value="emergency">Emergency</option>
      <option value="holding_cell">Holding Cell</option>
      <option value="fall_2024">Fall 2024</option>
      <option value="winter_2025">Winter 2025</option>
      {/*
      <option value="winter_2024">Winter 2024</option>
      <option value="mar_1_15">Floats Mar 1-15</option>
      <option value="mar_16_31">Floats Mar 16-31</option>
      <option value="apr_1_15">Floats Apr 1-15</option>
      <option value="apr_16_30">Floats Apr 16-30</option>
      <option value="may_1_15">Floats May 1-15</option>
      <option value="may_16_31">Floats May 16-31</option>
      <option value="jun_1_15">Floats Jun 1-15</option>
      <option value="jun_16_30">Floats Jun 16-30</option>
      <option value="jul_1_15">Floats Jul 1-15</option>
      <option value="jul_16_31">Floats Jul 16-31</option>
      <option value="aug_1_15">Floats Aug 1-15</option>
      <option value="aug_16_31">Floats Aug 16-31</option>
      <option value="sep_1_15">Floats Sep 1-15</option>
      <option value="sep_16_30">Floats Sep 16-30</option>
      <option value="oct_1_15">Floats Oct 1-15</option>
      <option value="oct_16_nov_30">Floats Oct 16-Nov 30</option>
      <option value="oct_16_dec_15">Floats Oct 16-Dec 15</option>
      <option value="oct_16_31">Floats Oct 16-31</option>
      */}
      <option value="nov_1_dec_4_sold_b4_aug1">Floats Nov 1-Dec 4 Sold B4 Aug 1</option>
      <option value="nov_1_dec_4_sold_b4_sep1">Floats Nov 1-Dec 4 Sold B4 Sep 1</option>
      <option value="nov_1_dec_4_sold_b4_oct1">Floats Nov 1-Dec 4 Sold B4 Oct 1</option>
      <option value="nov_1_10">Floats Nov 1-10</option>
      <option value="eaves_ready_now">Eaves Ready Now</option>
      <option value="nov_11_23">Floats Nov 11-23</option>
      <option value="nov_24_dec_4">Floats Nov 24-Dec 4</option>
      <option value="dec_5_23">Floats Dec 5-23</option>
      <option value="dec_16_31">Floats Dec 16-31</option>
      <option value="windows_pre_nov_10">Windows Pre-Nov10</option>
      <option value="windows_post_nov_10">Windows Post-Nov10</option>
      <option value="clean_my_eaves_when_possible">Clean my eaves when possible</option>
      {/*
      <option value="nov_1_dec_4">Floats Nov 1-Dec 4</option>
      <option value="nov_1_dec_31">Floats Nov 1-Dec 31</option>
      <option value="nov_1_10_windows">Floats Nov 1-10 Windows</option>
      <option value="nov_1_10_eaves">Floats Nov 1-10 Eaves</option>
      <option value="logan_nov_1_nov_10">Logan Nov 1-10</option>
      <option value="nov_11_23">Floats Nov 11-23</option>
      <option value="nov_16_30">Floats Nov 16-30</option>
      <option value="nov_24_dec_4">Floats Nov 24-Dec 4</option>
      <option value="dec_1_4">Floats Dec 1-4</option>
      <option value="dec_5_31">Floats Dec 5-31</option>
      <option value="dec_5_15">Floats Dec 5-15</option>
      <option value="buttonwood_nov_1_nov_10">Butt Nov 1-10</option>
      <option value="uoft_nov_1_nov_10">U of T Nov 1-10</option>


      <option value="logan_oct_16_nov_30">Logan Eaves</option>
      <option value="eaves_ready_now">Eaves Ready Now</option>
      <option value="eaves_now_or_never">Eaves Now Or Never</option>
      <option value="logan_oct_16_nov_30">Logan Eaves</option>
      <option value="eaves_ready_now">Eaves Ready Now</option>
      <option value="oct_16_31_windows">Floats Oct 16-31 Windows</option>
      <option value="spring_2023">Spring 2023</option>
      <option value="emergency_upcoming">Emergency Upcoming</option>


      <option value="oct_16_31_eaves">Floats Oct 16-31 Eaves</option>
      <option value="oct_16_31_eaves_ready_now">Floats Oct 16-31 Eaves Ready Now</option>
      <option value="nov_1_10_eaves">Floats Nov 1-10 Eaves</option>
      <option value="nov_1_10_eaves_ready_now">Floats Nov 1-10 Eaves Ready Now</option>
      <option value="nov_24_dec_15_sold_b4_oct1">Floats Nov 24-Dec 15 Sold B4 Oct 1</option>
      <option value="nov_24_dec_15_sold_after_oct1">Floats Nov 24-Dec 15 Sold After Oct 1</option>
      <option value="all_comm_nov_1_nov_10">Commercial Nov 1-10</option>
      <option value="new_clients">New Clients</option>
      <option value="appts">Appts</option>
      <option value="missed_appts">Missed Appts</option>
      <option value="all_ass_jobs">All Ass Jobs</option>
      <option value="partial_jobs">Partial Jobs</option>
      <option value="windows_pre_nov_10">Windows Pre-Nov10</option>
    */}
    </select>
  );
};

export default SelectRangeOptions;
