import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);


export const parseSalesData = (data, user, type) => {
  // console.log('DATA TO PARSE', data);
  let parsedData = {};

  if(type === 'INTERNAL_USER'){
    parsedData.salesid1 = user
  } else {
    parsedData.salesid1 = user
  }
  if(data.floatrange !== 'None Selected'){
    switch (data.floatrange) {
      case 'before Apr 1':
        parsedData.sdate = '2025-01-01';
        parsedData.fdate = '2025-03-31';
        parsedData.stime = null;
        break;
      case 'Apr 1-15':
        parsedData.sdate = '2025-04-01';
        parsedData.fdate = '2025-04-15';
        parsedData.stime = null;
        break;
      case 'Apr 16-30':
        parsedData.sdate = '2025-04-16';
        parsedData.fdate = '2025-04-30';
        parsedData.stime = null;
        break;
      case 'May 1-15':
        parsedData.sdate = '2025-05-01';
        parsedData.fdate = '2025-05-15';
        parsedData.stime = null;
        break;
      case 'May 16-31':
        parsedData.sdate = '2025-05-16';
        parsedData.fdate = '2025-05-31';
        parsedData.stime = null;
        break;
      case 'Jun 1-15':
        parsedData.sdate = '2025-06-01';
        parsedData.fdate = '2025-06-15';
        parsedData.stime = null;
        break;
      case 'Jun 16-30':
        parsedData.sdate = '2025-06-16';
        parsedData.fdate = '2025-06-30';
        parsedData.stime = null;
        break;
      case 'Jul 1-15':
        parsedData.sdate = '2025-07-01';
        parsedData.fdate = '2025-07-15';
        parsedData.stime = null;
        break;
      case 'Jul 16-31':
        parsedData.sdate = '2025-07-16';
        parsedData.fdate = '2025-07-31';
        parsedData.stime = null;
        break;
      case 'Aug 1-15':
        parsedData.sdate = '2025-08-01';
        parsedData.fdate = '2025-08-15';
        parsedData.stime = null;
        break;
      case 'Aug 16-31':
        parsedData.sdate = '2025-08-16';
        parsedData.fdate = '2025-08-31';
        parsedData.stime = null;
        break;
      case 'Sep 1-15':
        parsedData.sdate = '2025-09-01';
        parsedData.fdate = '2025-09-15';
        parsedData.stime = null;
        break;
      case 'Sep 16-30':
        parsedData.sdate = '2025-09-16';
        parsedData.fdate = '2025-09-30';
        parsedData.stime = null;
        break;
      case 'Oct 1-15':
        parsedData.sdate = '2025-10-01';
        parsedData.fdate = '2025-10-15';
        parsedData.stime = null;
        break;
      case 'Oct 16-31':
        parsedData.sdate = '2025-10-16';
        parsedData.fdate = '2025-10-31';
        parsedData.stime = null;
        break;
      case 'Nov 1-10':
        parsedData.sdate = '2025-11-01';
        parsedData.fdate = '2025-11-10';
        parsedData.stime = null;
        break;
      case 'Nov 11-23':
        parsedData.sdate = '2025-11-11';
        parsedData.fdate = '2025-11-23';
        parsedData.stime = null;
        break;
      case 'Nov 24-Dec 4':
        parsedData.sdate = '2025-11-24';
        parsedData.fdate = '2025-12-04';
        parsedData.stime = null;
        break;
      case 'Dec 5-15':
        parsedData.sdate = '2025-12-05';
        parsedData.fdate = '2025-12-15';
        parsedData.stime = null;
        break;
      case 'Dec 16-31':
        parsedData.sdate = '2025-12-16';
        parsedData.fdate = '2025-12-31';
        parsedData.stime = null;
        break;
      case 'Dec 5-23':
        parsedData.sdate = '2025-12-05';
        parsedData.fdate = '2025-12-23';
        parsedData.stime = null;
        break;
      default:
    }
  } else {
    const apptString = data.apptdate;
    console.log('> > > > > > apptdate:', data);
    let apptStringDayjs;
    if(data.apptdate.includes('Apr') || data.apptdate.includes('May') || data.apptdate.includes('Jun') || data.apptdate.includes('Jul')  || data.apptdate.includes('Aug') ) {
      apptStringDayjs = dayjs('2025 ' + apptString.substring(4,10), "YYYY MMM DD", 'en', true);
    }else{
      apptStringDayjs = dayjs('2025 ' + apptString.substring(4,10), "YYYY MMM DD", 'en', true);
    }
    let appt = dayjs(apptStringDayjs).format('YYYY-MM-DD');
    parsedData.sdate = appt;
    parsedData.fdate = appt;
    if(apptString.includes('8-10am')){
      parsedData.stime='8:00 AM';
    }else if(apptString.includes('12-2pm')){
      parsedData.stime='12:00 PM';
    }else{
      parsedData.stime='Anytime';
    }
  }

  parsedData.jobinfoid = data.address

  switch (data.jobtype) {
    case ('W1(Floater)'):
      parsedData.jobdesc = 'W1'
      parsedData.w1 = 0
      break;
    case ('W1(Appt Reqd)'):
      parsedData.jobdesc = 'W1'
      parsedData.w1 = 0
      break;
    case ('W2(Appt Reqd)'):
      parsedData.jobdesc = 'W2'
      parsedData.w2 = 0
      break;
    case ('W3(Appt Reqd)'):
      parsedData.jobdesc = 'W3'
      parsedData.w3 = 0
      break;
    case ('W4(Appt Reqd)'):
      parsedData.jobdesc = 'W4'
      parsedData.w4 = 0
      break;
    case ('EH'):
      parsedData.jobdesc = 'EH'
      parsedData.eh = 0
      break;
    case ('EG'):
      parsedData.jobdesc = 'EG'
      parsedData.eg = 0
      break;
    case ('EH + EG'):
      parsedData.jobdesc = 'EH + EG'
      parsedData.eg = 0
      parsedData.eh = 0
      break;

    default:
    parsedData.jobdesc = 'xxx'

  }

  if(data.cages){
    parsedData.cages = -1;
    parsedData.jobdesc = parsedData.jobdesc ? parsedData.jobdesc + ' + install cages(As Reqd)' : 'install cages(As Reqd)'
  }

  if(data.customdesc !== ''){
    parsedData.customdesc = data.customdesc
    parsedData.custom = 0
    parsedData.jobdesc = parsedData.jobdesc ? parsedData.jobdesc + ' + ' + parsedData.customdesc : parsedData.customdesc
  }

  parsedData.price = 0
  return parsedData
};

export const fullname = (client) => {
  let s = ''
  if(client.honorific !== null && client.honorific !== ''){
    s += client.honorific + ' ';
  }
  if(client.firstname !== null && client.firstname !== ''){
    s += client.firstname + ' ';
  }
  if(client.lastname !== null){
    s += client.lastname
  }
  return s;
}
