import React, { useState, useEffect } from "react";
import Layout from "../../layout/Layout";
import { Link, Redirect, useLocation } from "react-router-dom";

import ActionType from "../../../base/context/actionType";
import Loader from "../../../base/layout/Loader";
import { useStateReducer } from "../../../base/context/authContext";
import ApiClient from "../../../base/api/client";
import LoginAPI from "../../../base/api/login";

import { makeNiceDate, formatCurrency } from "../../helpers/helper";

const Admin = () => {
  const [{ user, mgr, userLoading}, dispatch] = useStateReducer();
  const location = useLocation();

  useEffect(() => {
    if (location && location.state) {
      console.log('Location:', location.state);

    }
  }, [location]);

  const changeMgr = async (e) => {
    const name = e.target.value;
    let payload = null;
    if(name === 'Jimmy'){
      payload = {
        hrid: 'HR00005495',
        name: 'Gezim Kapllani',
        royalty: '0.20'
      }
    }else if(name === 'JD'){
      payload = {
        hrid: 'HR00005658',
        name: 'James Garett Donovan',
        royalty: '0.30'
      }
    }else if(name === 'Roy'){
      payload = {
        hrid: 'HR00000762',
        name: 'Roy Cascayan',
        royalty: '0.30'
      }
    }else if(name === 'Enea'){
      payload = {
        hrid: 'HR00005978',
        name: 'Enea Pango',
        royalty: '0.30'
      }
    }else if(name === 'Justis'){
      payload = {
        hrid: 'HR00006257',
        name: 'Justis Nelson',
        royalty: '0.40'
      }
    }else if(name === 'Joel'){
      payload = {
        hrid: 'HR00005352',
        name: 'Joel Caprani',
        royalty: '0.30'
      }
    }else if(name === 'Roger'){
      payload = {
        hrid: 'HR00000001',
        name: 'Roger Singh',
        royalty: '0.30'
      }
    }
    if(payload){
      dispatch({
        type: ActionType.SetMgr,
        payload
      });
      dispatch({
        type: ActionType.SetBalance2023,
        payload: 0
      })
    }
    await LoginAPI.setMgr(payload.hrid);



  }

  return (
    <Layout>
      <div className="container">
        <nav className="breadcrumb">
          <ul>
            <li>
              <Link to="/partner_dashboard">Partner Dashboard</Link>
            </li>
            <li className="is-active">
              <Link to="/partner_admin">Admin</Link>
            </li>
          </ul>
        </nav>
        <hr />
        { user && (user.hrid === 'HR00000001' || user.hrid === 'HR00000429') ?
          <div className="select">
            <select onChange={ changeMgr }>
              <option value="none">None Selected</option>
              <option value="Jimmy">Jimmy</option>
              <option value="Roy">Roy</option>
              <option value="Enea">Enea</option>
              <option value="Justis">Justis</option>
              <option value="Roger">Roger</option>
              <option value="Joel">Joel</option>
              <option value="JD">JD</option>
            </select>
          </div> : 'Unauthorized Access'
        }
        <hr />
      </div>
    </Layout>
  );
};

export default Admin;
