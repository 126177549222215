import ApiClient from "./client";

export default class LoginAPI {
  static async logIn(username, password) {
    return ApiClient.post("/api/sessions", {
      username,
      password,
    });
  }

  static async logOut() {
    return ApiClient.request("/api/sessions/log_out");
  }

  static async verifySession() {
     return ApiClient.request("/api/sessions/verify_user");
  }

  static async verifyDashSession(cfid) {
     return ApiClient.post("/api/sessions/verify_dash_user", {cfid: cfid});
  }

  static async setMgr(hrid) {
    return ApiClient.post("/api/sessions/set_mgr", {
      hrid
    });
  }

  static async verifyMgr() {
    return ApiClient.request("/api/sessions/verify_mgr");
  }

  static async fetchRoyInfo() {
    return ApiClient.request("/api/sessions/fetch_roy_info");
  }




}
